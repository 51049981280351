import crypto from 'crypto';
const JWT_CLIENT_ENCRYPTION_SECRET: string = process.env
  .JWT_CLIENT_ENCRYPTION_SECRET as string; // Load from environment variable

export function encrypt(text: string) {
  if (!JWT_CLIENT_ENCRYPTION_SECRET) {
    throw new Error('ENCRYPTION_SECRET not set');
  }
  const iv = crypto.randomBytes(16);
  const cipher = crypto.createCipheriv(
    'aes-256-cbc',
    Buffer.from(JWT_CLIENT_ENCRYPTION_SECRET),
    iv,
  );
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return `${iv.toString('hex')}:${encrypted.toString('hex')}`;
}

export function decrypt(text: string) {
  if (!JWT_CLIENT_ENCRYPTION_SECRET) {
    throw new Error('ENCRYPTION_SECRET not set');
  }
  const textParts = text.split(':');
  // @ts-ignore
  const iv = Buffer.from(textParts.shift(), 'hex');
  const encryptedText = Buffer.from(textParts.join(':'), 'hex');
  const decipher = crypto.createDecipheriv(
    'aes-256-cbc',
    Buffer.from(JWT_CLIENT_ENCRYPTION_SECRET),
    iv,
  );
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
}
