import { AppConfig } from '../utils/config';
import {
  GetUserContainerResult,
  LoginErrorResponse,
  LoginSuccessTokenResponse,
} from './vytalTypes';
const fetch = require('node-fetch');

const defaultRequestHeaders = {
  'User-Agent': 'vytal-web-login',
};

export const Vytal = {
  /**
   * Load all containers from user
   */
  GetUserContainers: async (token: any): Promise<GetUserContainerResult> => {
    const response = await fetch(
      `${AppConfig.VYTAL_CAPI}/3/ContainerHistory/GetUserContainers?showActive=true`,
      {
        method: 'GET',
        headers: {
          ...defaultRequestHeaders,
          Accept: '*/*',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const data: GetUserContainerResult = await response.json();
    return data;
  },
  /**
   *
   * Call vytal endpoint to login with the passwordless login
   * @param email
   * @param token
   * @returns {Promise<boolean|*>}
   */
  passwordlessLogin: async (email: any, token: any) =>
    fetch(`${AppConfig.VYTAL_CAPI}/Account/PasswordlessLogin`, {
      method: 'POST',
      headers: {
        ...defaultRequestHeaders,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, token }),
    })
      .then((response: any) => response.json())
      .then((data: any) => {
        if (data.errorMessage) return { error: data };
        if (data.passwordlessLoginResponse) {
          return {
            data: {
              ...data,
              token: {
                token_type: 'Bearer',
                access_token: data.passwordlessLoginResponse.tokenString,
                refresh_token: data.passwordlessLoginResponse.refreshToken,
                expires_in: data.passwordlessLoginResponse.expiresInSeconds,
              },
            },
          };
        }
        return { data };
      }),
  /**
   * Call vytal endpoint to refresh the session with the refresh token
   * @param token
   * @param refreshToken
   * @returns {Promise<boolean|*>}
   */
  refreshToken: async (token: string, refreshToken: string) => {
    try {
      // Start Network Request
      const fetchResponse = await fetch(
        `${AppConfig.VYTAL_CAPI}/Account/refresh`,
        {
          method: 'POST',
          headers: {
            ...defaultRequestHeaders,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ExpiredJwtToken: token,
            RefreshToken: refreshToken,
          }),
        },
      );
      // Extract response data
      const data = await fetchResponse.json();
      if (data.errorMessage || !data.tokenString) {
        console.warn(
          `Error in vytalRefreshtoken request:\n ${JSON.stringify(data)}`,
        );
        throw new Error(JSON.stringify(data));
      }
      return {
        token_type: 'Bearer',
        access_token: data.tokenString,
        refresh_token: data.refreshToken,
        expires_in: data.expiresInSeconds,
      };
    } catch (e: any) {
      console.error('Request Error in vytal refreshToken', e, e.message);
      throw e;
    }
  },
  /**
   * Call vytal endpoint to passwordless signup a new user via email
   * @param email
   * @returns login_token_object {Promise<boolean|*>}
   *
   * Happy Response:
   * {
   *   passwordlessLoginResponse: {
   *     name: null,
   *       userId: 'd73f357c-1802-4168-a4dd-284f1c4e2dd2',
   *       tokenString: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpY2MiOiJ0Iiwic3ViIjoidG9iaW5lbnRpKzEyM0BnbWFpbC5jb20iLCJqdGkiOiI2Y2VkMjE4Yi04ZjNkLTRmOTgtOTRiYS00NWI1MWEzYzI3MDciLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9zaWQiOiJkNzNmMzU3Yy0xODAyLTQxNjgtYTRkZC0yODRmMWM0ZTJkZDIiLCJleHAiOjE1OTI1NzQ0MjksImlzcyI6InRlc3RpbmdpbnRlcm5hbHZ5dGFsb3JnIiwiYXVkIjoidnl0YWx1c2VycyJ9.akmV73U8jWmWfs_Odz-65h4bcd8hwFgaPKmCtLmJltI',
   *       refreshToken: 'LPNorHDRiqoS7/kQ3/D+moj3uyhnUH6OvqA1AT16ftI=',
   *       expiresInSeconds: 86400,
   *   },
   *   result: 'SUCCESS',
   *   success: true,
   *   errors: null,
   * }
   */
  passwordlessSignup: async (email: any) =>
    fetch(`${AppConfig.VYTAL_CAPI}/Account/PasswordlessSignup`, {
      method: 'POST',
      headers: {
        ...defaultRequestHeaders,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then((response: any) => response.json())
      .then((data: any) => {
        if (!data.success || !data.passwordlessLoginResponse) {
          console.warn(`Error in vytalPasswordlessSignup request:\n${data}`);
          throw new Error(JSON.stringify(data));
        }
        return {
          token_type: 'Bearer',
          access_token: data.passwordlessLoginResponse.tokenString,
          refresh_token: data.passwordlessLoginResponse.refreshToken,
          expires_in: data.passwordlessLoginResponse.expiresInSeconds,
        };
      }),
  externalLogin: async (
    token: string,
    authProvider: string,
  ): Promise<LoginSuccessTokenResponse | LoginErrorResponse | any> => {
    const fetchResponse = await fetch(
      `${AppConfig.VYTAL_CAPI}/2/Account/ExternalLogin`,
      {
        method: 'POST',
        headers: {
          ...defaultRequestHeaders,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          externalLoginData: token,
          externalLoginService: authProvider,
        }),
      },
    );

    // Extract response data
    const data = await fetchResponse.json();
    if (data.errors) return { error: data.errors };
    if (data.error)
      throw new Error(
        `Error in Vytal externalLogin:\n${data.error}\n${data.exception}`,
      );

    if (data.userId) {
      return {
        token_type: 'Bearer',
        access_token: data.tokenString,
        refresh_token: data.refreshToken,
        expires_in: data.expiresInSeconds,
      };
    }
    return { data };
  },
  vytalAppLogin: async (
    appLoginToken: string,
  ): Promise<LoginSuccessTokenResponse | LoginErrorResponse | any> => {
    const fetchResponse = await fetch(
      `${AppConfig.VYTAL_CAPI}/2/Account/VytalAppLogin`,
      {
        method: 'POST',
        headers: {
          ...defaultRequestHeaders,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          AppLoginTokenId: appLoginToken,
        }),
      },
    );

    // Extract response data
    const data = await fetchResponse.json();
    if (data.errors) return { error: data.errors };
    if (data.error)
      throw new Error(
        `Error in Vytal vytalAppLogin:\n${data.error}\n${data.exception}`,
      );

    if (data.userId) {
      return {
        token_type: 'Bearer',
        access_token: data.tokenString,
        refresh_token: data.refreshToken,
        expires_in: data.expiresInSeconds,
      };
    }
    return { data };
  },
};
