import { NextPageContext } from 'next';
import Cookies from 'cookies';
import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { useApollo } from './apolloClient';
import { decode } from 'jsonwebtoken';
import { Vytal } from './vytal';
import { CookieNames, setAuthCookies } from '../utils/cookies';
import { ServerResponse } from 'http';
import { ParsedUrlQuery } from 'querystring';
import { decrypt } from '../utils/crypto';
import { getWrappedLink } from '../utils/routerHelper';
import { resolveLocale } from '../utils/language';

export function withApolloClient(Component: any) {
  return ({
    authContext,
    initialApolloState,
    ...props
  }: {
    authContext?: AuthContext;
    initialApolloState: any;
  }) => {
    const apolloClient = useApollo(
      authContext || { jwt: null, refreshToken: null, clientId: null },
      initialApolloState,
    );
    return (
      <ApolloProvider client={apolloClient}>
        <Component {...props} />
      </ApolloProvider>
    );
  };
}

function handleUnauthorized(
  query: ParsedUrlQuery,
  res: ServerResponse,
  cookies: Cookies,
) {
  // this might be a problem, because some browsers (Chrome) do not allow to set cookies
  // on a redirect: https://stackoverflow.com/questions/57226104/cannot-set-headers-after-they-are-sent-to-the-client-with-express-validator-exp
  // cookies.set("jwt", null, { httpOnly: false, maxAge: -1})
  // cookies.set("refresh_token", null, { httpOnly: false, maxAge: -1})
  return {
    redirect: {
      destination: getWrappedLink('/welcome/login', query),
      permanent: false,
    },
  };
}

type WithAuthServerSidePropsOptions = {
  requireAuth?: boolean;
};

type VytalDecodedJwt = {
  exp: number;
  jti: string;
  iss: string;
  aud: string;
};

function isTokenExpired(jwt: string) {
  const decodedJwt = decode(jwt);
  if (!decodedJwt) return false;

  return Date.now() >= (decodedJwt as VytalDecodedJwt).exp * 1000;
}

export async function handleMaybeExpiredToken(
  jwtIn: string,
  refreshTokenIn: string | null,
) {
  let jwt = jwtIn;
  let refreshToken = refreshTokenIn;

  // check if jwt is expired
  if (jwt && isTokenExpired(jwt)) {
    // need to refresh the cookie
    if (refreshToken === null) {
      throw new Error('handleMaybeExpiredToken: no refresh_token available');
    } else {
      const { access_token: accessToken, refresh_token: newRefreshToken } =
        await Vytal.refreshToken(jwt, refreshToken);
      jwt = accessToken;
      refreshToken = newRefreshToken;
    }
  }
  return {
    jwt,
    refreshToken,
  };
}

export type AuthContext = {
  jwt: string | null;
  refreshToken: string | null;
  clientId: string | null;
};

export type DefaultPageProps = {
  authContext?: AuthContext;
  requestCookieConsent: boolean;
  cookieConsentGiven: boolean;
};

export function withAuthServerSideProps(
  getServerSidePropsFunc?: (
    authContext: AuthContext,
    locale: string | undefined,
  ) => Promise<any>,
  optionsIn?: WithAuthServerSidePropsOptions,
) {
  const { requireAuth } = optionsIn || {};
  return async (context: NextPageContext) => {
    const { req, res, query, locale } = context;
    const {
      disable_auto_login: disableAutoLoginIn,
      client_id: clientId,
      request_cookie_consent: requestCookieConsentUrlParam,
      cookie_consent_given: cookieConsentGivenUrlParam,
      encrypted_jwt: encryptedJwt,
      encrypted_refresh_token: encryptedRefreshToken,
    } = query;

    if (req && res) {
      res.setHeader('Cache-Control', 'no-store');
      const cookies = new Cookies(req, res, { secure: true });
      let jwt = cookies.get(CookieNames.jwt) || null;
      let refreshToken = cookies.get(CookieNames.refresh_token) || null;
      const isVytalCookieConsentCookieSet =
        cookies.get(CookieNames.vytal_cookie_consent) === 'true';

      const disableAutoLogin =
        disableAutoLoginIn && disableAutoLoginIn === 'true';
      if (disableAutoLogin) {
        // if disableAutoLogin is enabled, we do not want to discard the cookies which
        // might be already set
        setAuthCookies(cookies, '', '');
        jwt = '';
        refreshToken = '';
      }

      if (!jwt && !disableAutoLogin && encryptedJwt && encryptedRefreshToken) {
        // if no jwt is set, we try to use the encrypted_jwt and encrypted_refresh_token
        try {
          jwt = decrypt(encryptedJwt as string);
          refreshToken = decrypt(encryptedRefreshToken as string);
          setAuthCookies(cookies, jwt, refreshToken);
        } catch (e) {
          console.log(
            'Error withAuthServerSideProps decrypting jwt and refresh token',
            e,
          );
        }
      }

      if (requireAuth && !jwt) {
        return handleUnauthorized(query, res, cookies);
      }

      if (jwt) {
        try {
          const { jwt: newJwt, refreshToken: newRefreshToken } =
            await handleMaybeExpiredToken(jwt, refreshToken);
          if (jwt != newJwt || refreshToken != newRefreshToken) {
            setAuthCookies(cookies, newJwt, newRefreshToken as string);
            jwt = newJwt;
            refreshToken = newRefreshToken;
          }
        } catch (e) {
          console.log('Error handleMaybeExpiredToken', e);
          if (requireAuth) {
            return handleUnauthorized(query, res, cookies);
          }
        }
      }

      let mergedProps: DefaultPageProps = {
        requestCookieConsent: requestCookieConsentUrlParam === 'true',
        // cookie consent can either be given by url parameter from sdk or by local cookie (set when
        // user clicks confirm in cookie consent modal)
        // if requestCookieConsentUrlParam is not true, cookieConsentGiven is true by default
        cookieConsentGiven:
          requestCookieConsentUrlParam === 'true'
            ? cookieConsentGivenUrlParam === 'true' ||
              isVytalCookieConsentCookieSet
            : true,
      };

      const resolvedLocale = resolveLocale(locale, req);

      if (getServerSidePropsFunc) {
        try {
          const authContext: AuthContext = {
            jwt,
            refreshToken,
            clientId: (clientId as string | undefined) || null,
          };
          const result = await getServerSidePropsFunc(
            authContext,
            resolvedLocale,
          );
          mergedProps = {
            ...mergedProps,
            ...(result.props || {}),
            authContext,
          };
        } catch (e: any) {
          // handle Unauthorized error
          if (e.networkError && e.networkError.statusCode === 401) {
            return handleUnauthorized(query, res, cookies);
          } else {
            throw e;
          }
        }
      }

      return {
        props: mergedProps,
      };
    }
  };
}
